import { isAfter, isBefore, isEqual } from 'date-fns'

export default {
  methods: {
    convertSolutionTypesGroups (solutionTypes) {
      const convertedSolutionTypes = solutionTypes.map(item => {
        item.name = item.group ? this.getSolutionTypeGroup(item.group) : item.name

        return item
      })

      const uniqueConvertedSolutionTypes = convertedSolutionTypes.filter((item, index, self) =>
        item.group === null || self.findIndex(item2 => item2.group === item.group) === index
      )

      return uniqueConvertedSolutionTypes
    },
    getSolutionTypeGroup (group) {
      if (group === 'whatsapp') {
        return 'Curso WhatsApp'
      }

      if (group === 'activity') {
        return 'Atividade'
      }

      if (group === 'curso_ead') {
        return 'Curso EAD'
      }

      return group
    },
    isDateAfter (startDate, finalDate) {
      return isAfter(finalDate, startDate) || isEqual(startDate, finalDate)
    },
    isDateBefore (startDate, finalDate) {
      return isBefore(startDate, finalDate) || isEqual(startDate, finalDate)
    }
  }
}
